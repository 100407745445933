
    import Vue from 'vue';
    import { Component, Emit } from 'vue-property-decorator';

    @Component({
      components: {
      }
    })
    export default class Export extends Vue  {

    }
